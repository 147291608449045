@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/MaterialIcons-Regular.woff') format('woff'), /* Pretty Modern Browsers */
    url('fonts/MaterialIcons-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'passwordFont';
    src: url("fonts/password.ttf") format("truetype");
}