﻿.load-button {
    position: -webkit-sticky;
    position: sticky;
    background-color: #008CD2;
    margin-right: 5px;
    margin-left: 5px;
}

.modalBody {
    max-height: calc(100vh - 210px); 
    overflow-y: auto;
}

.loading-indicator{
    width: 32px;
    height: 32px;
}

.copy-loading-indicator {
    padding-left: 12px;
    width: 28px;
    height: 16px;
}

.status {
 float: right;
 padding-right: 10px;
}

.icons {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
}