/* Provide sufficient contrast against white background */
#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.load-button {
    border-color: #008CD2;
}

body {
    font: 16px 'Source Sans Pro', Arial, Helvetica, sans-serif;
}

.dropdown-item,
.dropdown-item:hover,
.dropdown-item:active {
    color: #212529;
}

.dropdown-item:hover,
.dropdown-item:active {
    background-color: #f2f2f2;
}

.nav-tabs .nav-link {
    cursor: default;
}

footer {
    text-align: center;
}

.custom-checkbox {
    width: 20px;
    height: 20px;
}
.custom-radioButton {
    width: 20px;
    height: 20px;
    margin-right:5px;
}

.user-select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.react-datepicker-wrapper {
    display: block;
    padding: inherit;
    border: inherit;
}

/* Scrollbars */

body::-webkit-scrollbar {
    width: 1em;
}

body::-webkit-scrollbar-track {
    background-color: #e9e9e9;
}

body::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
}

/* Modals */

.modal-body.no-border {
    padding: 0;
}

div.custom-items {
    display: flex;
}
h6.status{
    margin-left:10px;
}
.MuiCircularProgress-root.custom-loading-icon {
    display: none;
}
/* Pagination */
.pagination {
    justify-content: center;
}

.page-item.active .page-link {
    background-color: #ec611a;
    border-color: #ec611a;
}

.page-link {
    color: #364043;
}

.page-link:hover {
    color: #364043;
}
