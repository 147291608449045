$grey-7: #f2f2f2;

.login-form {
    float: right;
    width: 100%;
    background-color: $grey-7;
    display: flex;
    justify-content: space-between;

    form {
        float: right;
        display: flex;
        padding-left: 5px;

        .form-group {
            margin: 5px 5px 5px 0;
        }
    }
}

.login-label {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
        margin: 15px 15px 10px 15px;
        color: #5e5e5e;
    }
}

.panel {
    margin-bottom: 20px;
    background-color: #ffffff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

    .panel-heading {
        padding: 10px 15px;
        border-bottom: 1px solid transparent;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .panel-body {
        padding: 15px;
    }
}

.panel-success {
    border-color: #d6e9c6;

    .panel-heading {
        color: #3c763d;
        background-color: #dff0d8;
        border-color: #d6e9c6;
    }
}

.panel-default {
    border-color: #ddd;

    .panel-heading {
        border-color: #ddd;
    }
}

.signin {
    background-color: #ec611a;
}

.tag{
    padding-left:.5em;
}

@media all and (max-width: 800px) {
    .login-form {
        flex-direction: column;

        .form-group {
            margin-left: 5px;
        }

        form {
            flex-direction: column;
        }
    }

    .login-label {
        order: 0;
    }

    .form-group-email {
        order: 2;
    }

    .form-group-password {
        order: 3;
    }

    .form-group-signin {
        order: 4;
    }
}
