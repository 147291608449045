.top-menu {
    background-color: #364043;
}

.top-menu .navbar-nav .nav-link {
    color: #f5f5f5;
}

.top-menu .navbar-nav .nav-link:hover,
.top-menu .navbar-nav .nav-link:focus,
.top-menu .navbar-nav .nav-link:active {
    color: #ed601a;
}

.top-menu .navbar-nav .dropdown-menu {
    border-radius: 0;
    box-shadow: 0 0 20px rgba(0,0,0,.8);
    max-height: 400px;
    overflow: auto;
}

.navbar-nav .dropdown-header {
    color: #b1b1b3;
}

.navbar-nav .dropdown-item,
.navbar-nav .dropdown-header {
    padding: 0.5rem 2rem 0.5rem 2rem;
}

.navbar-nav .dropdown-header {
    padding-left: 1rem;
}

.navbar-brand {
    background-image: url(Vertafore-Logo-ToolBar.png);
    background-position: center;
    width: 100px;
    height: 40px;
}

.menu-avatar {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    font-style: normal;
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 32px;
    padding: 0;
    border: solid 1px #ec611a;
    background-size: 34px auto;
    background-position: center center;
    font-weight: normal;
    background-repeat: no-repeat;
    margin: 0.25rem 10px 0.25rem;
}

.menu-avatar,
.menu-avatar:active,
.menu-avatar:focus,
.menu-avatar:hover {
    background-color: #f5f5f5;
    color: #212529;
}

/* Scrollbars */

.navbar-nav .dropdown-menu::-webkit-scrollbar {
    width: 0.5em;
}

.navbar-nav .dropdown-menu::-webkit-scrollbar-track {
    background-color: #e9e9e9;
}

.navbar-nav .dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
}

.navbar-nav .dropdown-menu {
    scrollbar-width: thin; /* Firefox */
}

.client{
    color:white;
    margin-top:10px;
}