.toolbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .controls-container {
        align-self: flex-end;
        margin: 6px 6px 6px 0;
    }
}
