.page-item-text {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
}

.searchPage{
    width:75px;
}
