.eula-header {
    text-align: center;
}

.eula-content {
    text-align: justify;
    max-width: 1000px;
    margin: auto;
}

.eula-button-agree {
    display: flex;
    justify-content: flex-end;
    max-width: 1000px;
    margin: 20px 50px 20px 20px;
}
