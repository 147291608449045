.dynamic-data-table {
    table-layout: fixed;
    width: 100%;
    overflow-y: scroll;
    height: 100px;
    display: block;
}

.default-dynamic-column {
    width: 150px;
}

.date-dynamic-column {
    width: 150px;
}

.checkbox-dynamic-column {
    text-align: center;
    width: 150px;
}

.number-dynamic-column {
    width: 150px;
}

.email-dynamic-column {
    width: 225px;
}

.dynamic-data-table-not-found {
    display: block;
    font-size: 50px;
    font-weight: 800;
    color: red;
    padding: 20px;
}

.searchTool {
    width: 300px;
    float: right;
}

.hideScroll {
    overflow: hidden; /* hide scrollbars */
}

.search{
    width: 250px;
}