.data-table {

    th {
        vertical-align: middle !important;
        background-color: #f2f2f2;
    }

    td {
        vertical-align: middle;
        text-align: center;
    }

    tr {
        display: table;
        table-layout: fixed;
        width: 100%;
    }

    tr.nonFullWidthRow {
        display: table;
        table-layout: fixed;
        width: 100%;
        min-width: 1400px;
    }
}

.nonFullWidthRow {
    display: table;
    table-layout: fixed;
    width: 100%;
    min-width: 1400px;
}

td {
    overflow: hidden;
    text-overflow: ellipsis;
}

tbody {
    display: block;
    max-height: calc( 100vh - 380px );
    min-height: 80px;
    overflow-y: scroll;
}

tbody.summary {
    max-height: calc( 100vh - 282px );
}

tbody.statusSummary {
    max-height: calc( 100vh - 310px );
}

tbody.nonEntity {
    display: block;
    max-height: calc( 100vh - 220px );
    overflow-y: scroll;
}

thead {
    display: table;
}

.data-table-fixed {
    table-layout: fixed;
}

.type-style-vendor {
    margin-left: 80px;
    text-align: left;
}

.type-style-broker {
    margin-left: 20px;
    text-align: left;
}

.data-table-button {
    line-height: 20px;
    cursor: pointer;
    margin: 0 10px 0 10px;
}

.data-table-material-button {
    font-size: 20px;
}

.data-table-button:hover,
.data-table-button:active,
.data-table-button:focus {
    text-decoration: none;
    color: black;
}

td.number-column,
th.number-column {
    text-align: center;
    width: 50px;
    overflow: visible;
}

td.number-column-cs,
th.number-column-cs {
    text-align: center;
    width: 50px;
    height: 65px;
}


td.text-column,
th.text-column {
    text-align: center;
    width: 75px;
}

td.text-column-cs,
th.text-column-cs {
    text-align: center;
    width: 75px;
    height: 65px;
}

td.toggle-column,
th.toggle-column {
    width: 35px;
    white-space: nowrap;
}

td.toggle-column-cs,
th.toggle-column-cs {
    text-align: center;
    width: 35px;
    height: 65px;
}

td.controls-column,
th.controls-column {
    text-align: center;
    //width: 150px;
}

td.checkbox-col,
th.checkbox-col {
    text-align: center;
}

td.drop-col,
th.drop-col {
    text-align: center;
    //width: 250px;
}

td.center-aligning {
    text-align: center;
}

td.companyTitle,
th.companyTitle {
    width: 12.5%;
    text-align: left;
}

td.companyType,
th.companyType {
    width: 20%;
    text-align: center;
}


td.custom-dataCell {
    display: flex;
    flex-direction: column;
}

.complete {
    background-color: #f5f5f5;
}

td.status-column,
th.status-column {
    width: 5%;
}

th.status-column {
    overflow: visible;
    white-space: nowrap;
}

th.topTitle {
    width: 37.5%;
}

.ismapped {
    overflow: visible;
    white-space: nowrap;
}

.destinationTitle {
    width: 20%;
}

.destinationText {
    margin-right: 30%;
}

.companySpace {
    width: 13.5%;
}

.company-column {
    width: 10%;
}

.ismapped-column {
    width: 7%;
}

td.drop-column,
th.drop-column {
    width: 8.5%;
}

td.double,
th.double {
    width: 25%;
}

td.parent-writing,
th.parent-writing {
    width: 4%;
}

.mappedChecked {
    background-color: lightgray;
    text-decoration: line-through;
    text-indent: 10%;
}

.mappedUnchecked {
    text-indent: 10%;
}

.mappedUnchecked:hover {
    background-color: lightgray;
    text-decoration: line-through;
}

.cardBackground {
    background-color: WhiteSmoke;
    width: 100%;
    white-space: normal;
    justify-content: center;
}

.moveLeft {
    float: left
}

.tableSearch{
    width:265px;
    margin-left:100px;
}

.userTable {
    display: block;
    height: 100%;
    max-height: 10em;
    min-height: 80px;
    overflow-y: scroll;
}
.errorconv {
    background-color: #f18381;
}