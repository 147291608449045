.dynamic-data-table {
    table-layout: fixed;
    width:100%;
}

.default-dynamic-column{
}

.date-dynamic-column {
}

.checkbox-dynamic-column {
    text-align: center;
}

.number-dynamic-column {
}

.dynamic-data-table-not-found {
    display: block;
    font-size: 50px;
    font-weight: 800;
    color: red;
    padding: 20px;
}

.searchTool {
    width: 300px;
    float: right;
}

.report-column{
    width: 300px;
}