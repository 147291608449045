.splash-screen-container {
    display: flex;
    height: 100vh;

    .splash-side-image {
        display: flex;
        background-position: left center;
        background-repeat: repeat;
        flex: 1;
    }

    .splash-main {
        display: flex;
        flex-direction: column;
        flex: 3;
        justify-content: space-between;

        .splash-logo {
            display: flex;
            height: 50px;
            margin-left: 20px;
            margin-top: 20px;
            padding: 57px 0 12px;
            text-transform: uppercase;
            background-repeat: no-repeat;
        }

        .splash-footer {
            margin-left: 20px;
            margin-bottom: 5px;
        }
    }
}
