.buttons-panel {
    display: flex;
    justify-content: space-between;
}

.container {
    font-family: inherit;
}

.form-header {
    max-width: 500px;
    margin-left: 150px;
    padding-left: 20px;
    padding-right: 20px;

    h4 {
        padding-bottom: 20px;
    }
}

.form {
    margin-left: 200px;
    padding-left: 20px;
    padding-right: 20px;

    .form-group {
        width: 350px;
    }

    .row {
        .form-group {
            margin-right: 10px;
        }

        margin: unset;
    }
}

.input-panel {
    width: 720px;
}

.action-col {
    display: flex;
    justify-content: flex-end;

    button {
        background-color: #EC611A;
        margin-left: 10px;
    }
}

/* TODO: deprecate */
div.submit-button {
    display: flex;
    justify-content: flex-end;
    width: 720px;

    button {
        margin-right: 10px;
        background-color: #EC611A;
    }
}

button.submit-button-orange {
    background-color: #ec611a;
    border-color: #ec611a;
}
button.hide-submit-button{
    display:none;
}
.validationError {
    color: red;
    font-size: 80%;
}

.validationWarning {
    margin: 1% auto;
    color: gray;
    font-size: 80%;
}

.customMessage {
    text-align: center;
    margin-top: 20px;
}

.checkbox {
    margin-left: 10px;
    width: 20px;
    height: 20px;
}

$passwordFont: 'passwordFont', sans-serif;

.password {
    font-family: $passwordFont;
}

@media all and (max-width: 975px) {
    .form {
        .row {
            flex-direction: column;
        }
    }
}

.close{
    height:5px;
}
