.statistical-info-header {
    padding-left: 10px;
}
th{
    text-align:center;
}

.header-display {
    display: flex;
    justify-content: space-between;
}

.status1{
    padding-right: 50px;
}

.summaryLink{
    cursor:pointer;
}