.mini-column {
    width: 175px;
}

.mini-column-mid {
    width: 99px;
}

.mini-column-small {
    width: 88px;
}

.mini-column-large {
    width: 225px;
}

.mini-column-med {
    width: 135px;
}

.mini-column-mini {
    width: 16px;
}