.controls-container {
    display: flex;

    .input-group {
        width: unset;
    }
}

.mapping-button {
    background-color: #008CD2;
    margin-right: 5px;
    margin-left: 5px;
}

.mapped {
    text-align: center;
}

.employeeRow {
    overflow: hidden;
    white-space: nowrap;
}

.destination {
    width:10%;
}

.mappedValue {
    width: 20%;
}

.mappedValueTitle {
    margin-right: 30%;
}

.addToMainContainer {
    overflow: hidden;
}

.addToMain {
    float: left;
    width: 86%;
}

.activeRef {
    background-color:grey;
}

#addToMain {
    float: left;
    width: 1%;
}

.addToMainEpicCompany {
    float: left;
    width: 93%;
}

.addToMainEpic {
    float: left;
    width: 80%;
}

.checkbox-column-merge {
    width: 6%;
    overflow: visible;
    white-space: pre-wrap;
}

.rank-column {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.merge-name {
    width: 8%;
    overflow: visible;
    white-space: pre-wrap;
}

.hide-text {
    overflow: hidden;
}

.merge-name-toggle {
    width: 5%;
    overflow: visible;
    white-space: pre-wrap;
}


.merge-name-hidden {
    width: 8%;
    overflow: hidden;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.mergeButton {
    margin: 5px;
}