.controls-container {
    display: flex;

    .input-group {
        width: unset;
    }
}

.mapping-button {
    background-color: #008CD2;
    margin-right: 5px;
    margin-left: 5px;
}

.mapped{
    text-align: center;
}

th {
    cursor: pointer;
}

th:hover {
    background-color: #f0f8ff;
    color: #364043;
}

th i {
    visibility: hidden;
}

th:hover i {
    visibility: visible;
}