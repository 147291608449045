$sidebarWidth: 240px;
$separatorWidth: 15px;

#mapping-sidebar-container {
    display: flex;
    width: 100%;

    #mapping-sidebar {
        display: flex;
        flex-direction: row;
        position: relative;

        & > .nav {
            width: $sidebarWidth;
            padding-top: 20px;

            .nav-header {
                a.nav-toggler {
                    padding-left: 0.5rem;
                    color: #ec611a
                }

                a.disabled {
                    color: #dddddd;
                }
            }

            a {
                color: #364043;
            }

            a.active, a.active:hover {
                background-color: #008cd2;
                color: #f5f5f5;
            }

            a:hover {
                background-color: #f0f8ff;
                color: #364043;
            }
        }
    }

    #mapping-sidebar::after {
        content: '';
        display: block;
        position: absolute;
        left: ($sidebarWidth - $separatorWidth);
        width: $separatorWidth;
        height: 100%;
        background: repeating-linear-gradient(135deg, #f2f2f2, #f2f2f2 10px, #ffffff 10px, #ffffff 20px);
        z-index: -100;
    }

    #mapping-sidebar + * {
        padding-top: 20px;
        width: calc(100vw - 240px);
        padding-top: 20px;
    }
}

.is-mapped-icon {
    font-weight: 900;
    font-size: 20px;
}

li.nav-header {
    font-weight: 700;
    color: #ec611a;
    cursor: pointer;

    li {
        font-weight: 400;
    }
}

.page-not-available {
    padding: 40px 40px;

    .not-available-icon {
        font-size: 3rem;
    }

    .not-available-message {
        font-size: 1.5rem;
        padding-left: 10px;
        vertical-align: middle;
    }
}

#mapping-sidebar-header {
    color: #b1b1b3;
    min-height: 2rem;
    height: auto;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}


.subNav{
    text-indent:8px;
}

.subBackground {
    background-color: rgba(255, 85, 0, 0.1);
}

.flatlink {
    display:block;
    text-align:center;
    text-decoration: none;
    margin-left:10px;
}

.tier-summary-icon {
    position: absolute;
    margin-top: -28px;
    margin-left: 200px;
}

.imageLogIcon {
    padding-left:71.68%;
}

.timeLog {
    font-size: 80%;
    margin-right: 1.5%;
}

.imageLog{
    height: 80%;
}